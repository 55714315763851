import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const BottomDrawerModal = ({
  children,
  isOpen,
  setIsOpen,
  background = "#FFF",
  isMenu = false,
  onClose,
  sponsorModal = false,
}) => {
  return (
    <div className="">
      <AnimatePresence>
        {isOpen && (
          <>
            {/* Bottom Drawer */}
            <motion.div
              className="position-fixed bottom-0 start-0 bottom-drawer-modal-cont px-3"
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "ease-in-out", bounce: 0, duration: 0.5 }}
            >
              {children}
            </motion.div>
            {/* Backdrop */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ type: "spring", bounce: 0, duration: 0.2 }}
              onClick={() => {
                if (onClose) onClose();
                if (setIsOpen) setIsOpen(false);
              }}
              style={{ background: "rgba(0, 0, 0, 0.75)", zIndex: "3001" }}
              className="px-5 position-fixed vh-100 vw-100 d-flex align-items-center justify-content-center top-0 start-0"
            />
          </>
        )}
      </AnimatePresence>
      <style>{`
        .bottom-drawer-modal-cont {
          background: ${background} ;
          z-index: 3002;
          width: 100%;
          height: 550px; 
          max-height: 90vh; 
          overflow-y: ${sponsorModal ? "none" : "auto"};
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }

        .custom-height {
          padding-top: 20px;
          padding-bottom: 20px;
          overflow: hidden;
        }

        @media (max-width: 1200px) {
          .bottom-drawer-modal-cont {
            max-width: 100%;
          }
        }

        @media (max-width: 768px) {
          .bottom-drawer-modal-cont {
            height: max-content;
            max-height: 550px;
            max-width: 100%;
            padding-top: 10px !important;
          }
        }
        @media (max-width: 576px) {
          .bottom-drawer-modal-cont {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default BottomDrawerModal;
