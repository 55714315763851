import { useRef, useState, useEffect } from "react";

export const useSectionRefs = () => {
  const aboutRef = useRef(null);
  const ticketsRef = useRef(null);
  const speakersRef = useRef(null);
  const workshopsRef = useRef(null);
  const agendaRef = useRef(null);
  const sponsorsRef = useRef(null);
  const partnersRef = useRef(null);
  const exhibitorsRef = useRef(null);
  const venueRef = useRef(null);

  const sections = {
    about: aboutRef,
    tickets: ticketsRef,
    speakers: speakersRef,
    workshops: workshopsRef,
    agenda: agendaRef,
    sponsors: sponsorsRef,
    partners: partnersRef,
    exhibitors: exhibitorsRef,
    venue: venueRef,
  };

  return { sections };
};

//hook for mobile screens 
export const useIsMobile = (breakpoint = 768) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= breakpoint);
    
    // Initial check
    handleResize();
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [breakpoint]); // Add breakpoint as dependency if you want it configurable

  return isMobile;
};

